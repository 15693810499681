import { createStore as _createStore } from 'vuex'
import store from './store'
import user from './user'

export function createStore() {
  return _createStore({
    modules: {
      store,
      user,
    },
  })
}
