import { getAuth } from 'firebase/auth'

export default {
  state: () => ({
    photoURL: '',
    email: '',
  }),
  getters: {},
  mutations: {},
  actions: {
    authStateChanged: ({ dispatch }) => {
      return new Promise((resolve, reject) => {
        const auth = getAuth()
        auth.onAuthStateChanged((user) => {
          dispatch('fetchUser', user)
          resolve(user)
        }, reject)
      })
    },
    logOut: () => {
      const auth = getAuth()
      auth.signOut().then(() => {
        this.$router.replace({
          name: 'home',
        })
      })
    },
  },
}
