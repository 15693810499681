<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script setup>
  import { watchEffect, shallowRef, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import AppLayoutDefault from './AppDefaultLayout.vue'

  const layout = shallowRef(AppLayoutDefault)
  const route = useRoute()

  // themeColors
  let themeOverrides = ref({})

  watchEffect(() => {
    themeOverrides.value = {
      common: {
        primaryColor: 'red',
        primaryColorHover: 'pink',
      },
    }
  })

  watchEffect(async () => {
    const component = route.meta.layout
      ? await import(/* @vite-ignore */ `./${route.meta.layout}Layout.vue`)
      : null
    layout.value = component?.default || AppLayoutDefault
  })
</script>
