import App from './App.vue'
import { createSSRApp } from 'vue'
import { setup } from '@css-render/vue3-ssr'
import { createRouter } from './router'
import { createStore } from './stores/index'
import { setupI18n } from './plugins/i18n'
import { initializeApp } from 'firebase/app'
import './index.css'
// SSR requires a fresh app instance per request, therefore we export a function
// that creates a fresh app instance. If using Vuex, we'd also be creating a
// fresh store here.
export function createApp() {
  const app = createSSRApp(App)
  const router = createRouter()
  const { collect } = setup(app)
  const i18n = setupI18n()
  const store = createStore()

  const firebaseConfig = {
    apiKey: 'AIzaSyDOHiFBUBgtKc59DmDLMLu2SzwF3I5uis8',
    authDomain: 'loyalty-611b1.firebaseapp.com',
    projectId: 'loyalty-611b1',
    storageBucket: 'loyalty-611b1.appspot.com',
    messagingSenderId: '551239201309',
    appId: '1:551239201309:web:86ecc0a2e4bfd8b4810cf4',
    measurementId: 'G-NKGQ8PBLJH',
  }

  initializeApp(firebaseConfig)

  app.use(router)
  app.use(store)
  app.use(i18n)

  return { app, router, collect }
}
