import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory,
} from 'vue-router'
import { createStore } from './stores'
const store = createStore()

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('./views/pages/HelloWorld.vue'),
    meta: {
      layout: 'Base',
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/pages/Login.vue'),
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import('./views/pages/Registration.vue'),
    meta: {
      layout: 'Auth',
    },
  },
]

export function createRouter() {
  const router = _createRouter({
    history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    routes,
  })

  router.beforeEach(async (to, from, next) => {
    const isRequireAuth = to.matched.some(
      (record) => record.meta['requiresAuth']
    )
    if (isRequireAuth && !(await store.dispatch('authStateChanged'))) {
      next('Login')
    }
    next()
  })
  return router
}
