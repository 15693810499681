import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import * as yup from 'yup'

function setYupLocale(i18n) {
  yup.setLocale({
    mixed: {
      required: () => i18n.global.t('validation.required'),
      min: () => i18n.global.t('validation.min'),
    },
    string: {
      email: () => i18n.global.t('validation.email'),
      min: ({ min }) => i18n.global.t('validation.min', [ min ]),
    },
  })
}

export function setupI18n() {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'hu-HU',
    fallbackLocale: 'hu-HU',
  })
  setI18nLanguage(i18n, 'hu-HU')
  loadLocaleMessages(i18n, 'hu-HU')
  loadLocaleMessages(i18n, 'en-GB')
  setYupLocale(i18n)
  return i18n
}

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
}

//Use when got user locale from server
export async function loadLocaleMessages(i18n, locale) {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `../locales/${locale}.json`
  )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)

  return nextTick()
}

// yup.setLocale({
//   mixed: {
//     required: () => i18n.global.t('validation.required')
//   },
//   string: {
//     email: () => i18n.global.t('validation.email')
//   },
//   array: {
//     min: ({ min }) => i18n.global.t('validation.min_value', [min])
//   }
// })
