export default {
  state: () => ({
    language: 'hu-HU',
  }),
  getters: {
    language: (state) => state.language,
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      state.language = lang
    },
  },
  actions: {
    setLocalStoreLanguage: ({ commit }, { lang, hasToReload }) => {
      commit('SET_LANGUAGE', lang)
      if (hasToReload) {
        return location.reload()
      }
    },
  },
}
